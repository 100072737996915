<template>
  <div>
    <v-container fluid>
      <v-widget title="Movimento de Produção">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="10" sm="5">
                <v-text-field flat single-line hide-details clearable solo v-model="busca" prepend-inner-icon="search" label="Busca" background-color="grey lighten-3"></v-text-field>
              </v-col>
              <v-col cols="2" sm="7" class="text-right">
                <v-btn v-if="!isSm" depressed color="primary" @click="novo">
                  NOVO
                </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="movimentos" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">{{ item.data | formatarData }}</td>
                <td class="text-center">{{ item.produto.codigo }}</td>
                <td>{{ item.produto.descricao }}</td>
                <td class="text-center">{{ item.quantidade | formatarValor }}</td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition" @click.native.stop="">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item :key="item._id + 15" @click="excluir(item._id)" data-cy="remover">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>

    <v-dialog v-model="dialogFormulario" persistent max-width="800px" scrollable>
      <v-card>
        <v-toolbar flat dense dark color="primary">
          <v-toolbar-title>Movimento de Produção</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 pt-5">
          <v-form v-model="formValid" ref="form">
            <v-row dense>
              <v-col cols="3">
                <campo-data persistent-placeholder outlined v-model="movimento.data" label="Data" ref="data" @keydown.native.enter="$refs.buscaProduto.focus()" :rules="[rules.obrigatorio]"></campo-data>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Produto" ref="buscaProduto" persistent-placeholder outlined v-model="produto_texto" append-icon="mdi-magnify" @click="abrirBuscaProdutos" @click:append="abrirBuscaProdutos" style="cursor: pointer"></v-text-field>
              </v-col>
              <v-col cols="3">
                <as-campo-valor label="Quantidade" persistent-placeholder outlined v-model="movimento.quantidade" :decimais="2" ref="quantidade" @keydown.native.enter="salvar" @input="calcularBaixaInsumos" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]"></as-campo-valor>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea name="obs" label="Observação" persistent-placeholder outlined v-model="movimento.observacao" ref="obs" @keydown.native.enter="salvar" rows="2"></v-textarea>
              </v-col>
            </v-row>
            <v-row dense v-if="movimento.quantidade > 0">
              <v-col cols="12">
                <v-divider></v-divider>
                <v-card>
                  <v-toolbar flat dense dark color="primary">
                    <v-toolbar-title>Insumos</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="pt-1">
                    <v-data-table hide-default-footer :headers="headers_insumos" :items="movimento.produto.insumos">
                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="text-center">{{ item.produto.codigo }}</td>
                          <td>{{ item.produto.descricao }}</td>
                          <td class="text-center">{{ item.quantidade | formatarValor }}</td>
                          <td class="text-center">{{ item.baixa_estoque | formatarValor }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-col class="text-right">
                <v-btn class="mr-2" @click="dialogFormulario = false" text>Cancelar</v-btn>
                <v-btn color="primary" @click="salvar" :loading="carregando" :disabled="!formValid">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-buscar-produto ref="dialogBuscarProduto" @produtoSelecionado="selecionarProduto" :produtos-proprios="true"></dialog-buscar-produto>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import constantes from '@/util/constantes';
import { clone } from '@/util/helpers';
import DialogBuscarProduto from '@/components/dialogs/DialogBuscarProduto';
import ServiceProdutos from '@/services/produtosApi';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'ListaMovimentoProducao',
  components: {
    DialogBuscarProduto,
  },
  data() {
    return {
      constantes: constantes,
      dialogFormulario: false,
      formValid: false,
      busca: '',
      produto_texto: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        { text: 'Data', value: 'data', sortable: false, width: '10%', align: 'center' },
        { text: 'Código', value: 'produto.codigo', sortable: false, width: '15%', align: 'center' },
        { text: 'Descrição', value: 'produto.descricao', sortable: false, width: '55%' },
        { text: 'Quantidade', value: 'quantidade', sortable: false, width: '15%', align: 'center' },
        { text: 'Ações', value: 'acoes', align: 'center', sortable: false, width: '5%' },
      ],
      headers_insumos: [
        { text: 'Código', value: 'produto.codigo', sortable: false, width: '20%', align: 'center' },
        { text: 'Descrição', value: 'produto.descricao', sortable: false, width: '45%' },
        { text: 'Quantidade', value: 'quantidade', sortable: false, width: '15%', align: 'center' },
        { text: 'Baixa Estoque', value: 'baixa_estoque', sortable: false, width: '20%', align: 'center' },
      ],
      rules: { ...regras },
    };
  },
  computed: {
    ...mapState('movimentoProducao', {
      movimento: 'movimentoAtual',
      movimentos: 'movimentos',
      total: 'total',
      carregando: 'carregando',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    async listar() {
      await this.$store.dispatch('movimentoProducao/listar', this.options);
    },
    novo() {
      this.$store.commit('movimentoProducao/ZERAR');
      this.produto_texto = '';
      this.dialogFormulario = true;
    },
    abrirBuscaProdutos() {
      this.$refs.dialogBuscarProduto.abrir();
    },
    async selecionarProduto(produto_selecionado) {
      let insumos = await ServiceProdutos.carregarInsumos(produto_selecionado._id);

      this.movimento.produto = {
        produto: produto_selecionado._id,
        codigo: produto_selecionado.codigo,
        descricao: produto_selecionado.descricao,
        insumos: insumos,
      };
      this.produto_texto = produto_selecionado.codigo + ' - ' + produto_selecionado.descricao;
      this.movimento.quantidade = 0;
      setTimeout(() => {
        this.$refs.quantidade.focus();
      }, 500);
    },
    async salvar() {
      await this.$store.dispatch('movimentoProducao/salvar', this.movimento);
      this.dialogFormulario = false;
    },
    excluir(movimento_id) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir essa produção?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('movimentoProducao/excluir', movimento_id);
        }
      });
    },
    calcularBaixaInsumos() {
      let produto_insumos = this.movimento.produto.insumos.map((insumo) => {
        insumo.baixa_estoque = insumo.quantidade * this.movimento.quantidade;
        return insumo;
      });

      this.movimento.produto.insumos = produto_insumos;
    },
  },
  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
